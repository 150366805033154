<template>
  <div>
  </div>
</template>
<script>
import {getSocialLoginCallback, socialLoginCallback} from "../components/NetWork/request";
import {mapActions} from 'vuex'

export default {
  name: 'SocialCallback',
  created() {
    this.$Message.loading('登录中......')
    console.log('登录=========')
    const socialType = this.$route.query.socialType
    const param =this.$route.query
    // this.socialCallback(socialType, param)
    getSocialLoginCallback(socialType, param)
        .then(res=>{
          if (res.data.code === 200) {
            console.log(res.data)
            localStorage.setItem("token", res.data.result.token);
            /* 保存一下刚刚登陆的账号 */
            this.$Message.success("登陆成功啦,马上带您去游览我的小站!");
            setTimeout(() => {
              this.$router.push('/profile')
            }, 1000);
            // setTimeout(() => {
            //     this.$router.push('/profile')
            // }, 1000);
          } else if (res.data.err == -998) {
            /* 报错查不到用户 那么我们就要进行一个注册 */
            this.$Message.error(res.data.message);
          } else {
            this.$Message.error(res.data.message);
          }
          this.$store.commit("LoadingTitleChange", {
            isShow: false,
            title: ""
          });
        });
  },
  methods: {
    ...mapActions(['Login']),
    getUrlKey: function (name) {
      // eslint-disable-next-line no-sparse-arrays
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(window.opener.location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    },
    socialCallback(socialType, parameter) {
      console.log('调用后端回调接口 type==>', socialType)
      console.log('调用后端回调接口 param==>', parameter)
      socialLoginCallback(socialType, parameter).then(r => {
        console.log('后端回调返回res=', r)
        if (r.data.code !== 0) {
          this.$Message.error(r.data.message)
          this.$router.push('/login')
        }
      });
      // //调用后端回调地址拿到登录信息
      // socialLoginCallback(socialType, parameter).then(res => {
      //   // that.$loading.hide()
      //   // this.$Message.destroy();
      //   console.log('后端回调返回res=', res)
      //   const bindResult = res.data
      //   if (bindResult && bindResult !== '') {
      //     if (bindResult.success && bindResult.data) {
      //       // 授权后发现已绑定，那么直接调用第三方登录
      //       this.socialLogin(bindResult.data)
      //     } else if (bindResult.code === 601) {
      //       // 授权后没有绑定则跳转到绑定界面
      //       that.$router.push({name: 'socialBind', query: {redirect: this.getUrlKey('redirect'), key: bindResult.data}})
      //     } else if (bindResult.code === 602) {
      //       // 该账号已绑定多个账号，请联系系统管理员，或者到个人中心解绑
      //       this.$Message.error('该账号已绑定多个账号，请联系系统管理员，或者到个人中心解绑');
      //       // this.$notification['error']({
      //       //   message: '错误',
      //       //   description: ((res.response || {}).data || {}).message || '该账号已绑定多个账号，请联系系统管理员，或者到个人中心解绑',
      //       //   duration: 4
      //       // })
      //     } else {
      //       // 提示获取第三方登录失败
      //       this.$Message.error('第三方登录失败，请稍后再试')
      //       // this.$notification['error']({
      //       //   message: '错误',
      //       //   description: '第三方登录失败，请稍后再试',
      //       //   duration: 4
      //       // })
      //     }
      //   } else {
      //     // 提示获取第三方登录失败
      //     this.$Message.error('第三方登录失败，请稍后再试')
      //     // this.$notification['error']({
      //     //   message: '错误',
      //     //   description: '第三方登录失败，请稍后再试',
      //     //   duration: 4
      //     // })
      //   }
      // })
    },
    // 第三方登录后回调
    socialLogin(key) {
      const {Login} = this
      // 执行登录操作
      const loginParams = {
        grant_type: 'social',
        social_key: key
      }
      this.$Message.loading('登录中......')
      Login(loginParams)
          .then((res) => this.loginSuccess(res))
          .catch(err => this.loginError(err))
          .finally(() => {
            // this.$loading.hide()
            // this.$Message.destroy();
            if (this.getUrlKey('redirect')) {
              window.opener.location.href = window.opener.location.origin + this.getUrlKey('redirect')
            } else {
              window.opener.location.reload()
            }
            window.close()
          })
    },
    loginSuccess() {
      this.$Message.success('第三方登录成功')
      // this.$notification['success']({
      //   message: '提示',
      //   description: '第三方登录成功',
      //   duration: 4
      // })
    },
    loginError() {
      this.$Message.error('请求出现错误，请稍后再试')
      // this.$notification['error']({
      //   message: '错误',
      //   description: ((err.response || {}).data || {}).message || '请求出现错误，请稍后再试',
      //   duration: 4
      // })
    }
  }
}
</script>
<style>
</style>